'use client'
import { verifyToken } from '@/actions/authActions'
import { UserData, AuthSwitch } from '@/auth'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { getCookie } from '@/actions/coockiesActions'

//The UserAuthComponent component handles user authentication by checking cookies, displaying user data, logging in, and logging out the user.
const UserAuthComponent = () => {

  const [openAuth, setOpenAuth] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(false)
  /**
   * @function checkCookies - This function checks the cookies and verifies that the user is authenticated.
   */
  const checkCookies = async () => {
    const { user, success, token } = await verifyToken()
    setSession(token);
    if (success) {
      setUser(user)
      Cookies.set('dni', user.dni, { expires: 1 })
    } else {
      setUser(null)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    checkCookies()
  }, []);

  if (isLoading) { return (<div className='w-28 h-10 rounded-xl bg-primary animate-pulse'></div>) }

  return (
    <>
      {
        user ?
          <div className='w-auto gap-x-4 flex items-center'>
            <Link href={`/broker/?token=${session}`} className='border-b-2 border-primary text-primary flex justify-center items-center md:text-xs xl:text-base font-semibold hover:scale-110 transition-all duration-300'>
              Mis pólizas
            </Link>
            <button type='button' onClick={() => UserData(user, checkCookies)} className='hover:scale-110 transition-all duration-300' >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-circle" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
              </svg>
            </button>
          </div>
          :
          <button onClick={() => { setOpenAuth(true) }} type='button' className='px-5 py-2 bg-primary md:text-xs xl:text-base font-semibold text-white rounded-xl shadow hover:scale-105 transition-all duration-300'>
            Ingresar
          </button>
      }
      {openAuth && <AuthSwitch checkCookies={checkCookies} authVisible={openAuth} setAuthVisible={setOpenAuth} />}
    </>
  )
}

export default UserAuthComponent