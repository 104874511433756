'use client'
import React, { useState } from 'react'
import useForm from '@/hooks/useForm';
import { CommonInput, CommonModal } from '@/components';
import { loginValidator } from '../auth_validators';
import { toast } from 'sonner';
import { authServices } from '@/services/Auth';
import { loginInputs } from '@/utils';
import Link from 'next/link';

//The login component is responsible for managing the user login.
function Login({ checkCookies, authVisible, setAuthVisible, setStep }) {

  const [remember, setRemember] = useState(false)
  const [seePassword, setSeePassword] = useState(false);

  const { form, handleChange } = useForm({
    email: '',
    password: ''
  })

  const [isSubmiting, setIsSubmiting] = useState(false)

  /**
   * @function sendData - Validates the login form, sends the data to the server, and handles the response.
  */
  const sendData = async (e) => {
    e.preventDefault()

    setIsSubmiting(true)

    const { validation, message } = loginValidator(form);

    if (!validation) {
      toast.error(message)
      setIsSubmiting(false)
      return
    }

    const { login } = authServices();

    try {
      const { status, data } = await login(form)

      switch (status) {
        case 201:
          setAuthVisible(false)
          checkCookies();
          toast.success(`Bienvenido ${data.name}`)
          break;
        case 401: toast.error('Credenciales invalidas')
          break;
        case 500:
          toast.error('Error en la autenticacion de usuario')
          break;
        default: toast.error('Error en la autenticacion de usuario')
      }
    } catch (error) {
      return toast.error('Error en la autenticacion de usuario')
    } finally {
      setIsSubmiting(false)
    }

  }

  return (
    <CommonModal open={authVisible} setOpen={setAuthVisible}>
      <section className="relative z-10 flex flex-col gap-y-8 w-full h-auto">
        <h1 className='text-3xl font-semibold'>Ingresar</h1>
        <article className='flex gap-x-3'>
          <h4 className='text-gray-500 font-semibold'>No tienes una cuenta?</h4>
          <Link href={'/register'} className='text-primary font-semibold underline underline-offset-4 ml-1'>Crea una</Link>
        </article>
        <form onSubmit={sendData} className='flex flex-col w-full gap-y-5'>
          {
            loginInputs.map(item => (
              <article key={item.name} className={`${item.width} h-20`}>
                <CommonInput values={item} onChange={handleChange} />
              </article>
            ))
          }
          <label className="flex flex-col justify-center w-full h-14 relative">
            <span className={`flex text-xs font-semibold h-auto text-black`}>Contraseña</span>
            <input
              onChange={handleChange}
              type={ seePassword ? 'text' : 'password'}
              name='password'
              required={true}
              minLength={8}
              maxLength={25}
              placeholder='*********'
              value={form?.password}
              className={`w-full h-11 flex-grow px-3 text-sm font-semibold rounded-sm text-gray-800 ring-1 ring-gray-200 focus:ring-2 focus:ring-primary focus:outline-none`}
            />
            <button className='absolute right-3 bottom-2' type='button' onClick={() => setSeePassword(!seePassword)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                {
                  seePassword ?
                    <>
                      <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0">
                      </path>
                      <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6">
                      </path>
                    </>
                    :
                    <>
                      <path d="M21 9c-2.4 2.667 -5.4 4 -9 4c-3.6 0 -6.6 -1.333 -9 -4"></path> <path d="M3 15l2.5 -3.8"></path> <path d="M21 14.976l-2.492 -3.776"></path> <path d="M9 17l.5 -4"></path> <path d="M15 17l-.5 -4"></path>
                    </>
                }
              </svg>
            </button>
          </label>
          <article className='w-full flex items-center justify-between'>
            <div className='flex items-center'>
              <input type="checkbox" onChange={() => setRemember(!remember)} checked={remember} />
              <p className='text-sm text-gray-500 ml-1'>Recordarme</p>
            </div>
            <button type='button' onClick={() => setStep(1)} className='text-primary text-sm font-semibold underline underline-offset-4 '>¿Olvidaste tu Contraseña?</button>
          </article>
          <button type='submit' disabled={isSubmiting} className={`w-full h-min py-3 text-white font-semibold text-sm rounded-full flex justify-center items-center bg-primary transition-all duration-300 ${isSubmiting && 'animate-pulse bg-opacity-30'}`}>
            {isSubmiting ? 'Iniciando...' : 'Inciar sesión'}
          </button>
        </form>
      </section>
    </CommonModal>
  );
}
export default Login