'use client'
import React, { useState } from 'react'
import { laHolandoServices } from '@/services/LaHolando';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { toast } from 'sonner';
import { LoadingModal } from '@/app/broker/insuranceType/policies';

//This component renders an policie card.
const Policie_Card = ({ policie, cod_sec }) => {

  const { poliza, modelo, emi, venc, company } = policie;

  const [isSubmiting, setIsSubmiting] = useState(false)

  //State that manages the PDF visualization
  const [pdf, setPdf] = useState(null)

  const router = useRouter()

  //This function is responsible for fetching the PDF from the database while displaying a loading modal.
  const fetchAndDecodeBase64PDF = async () => {
    //Open loading modal
    setIsSubmiting(true)

    const { laHolandoGetPoliza } = laHolandoServices();

    try {
      //It retrieves the policy using the policy number and the section code.
      const response = await laHolandoGetPoliza(poliza, cod_sec)
      const { data, status } = response;

      //Since the PDF takes more than 20 seconds to arrive, the database will return a status of 'processing' while it is searching for the PDF. Therefore, a timeout is triggered every 5 seconds to keep the loading modal open and call the function again. Once the PDF is retrieved, the loading modal is closed, and the user can view or share the PDF.

      //As long as the request is not successful or there is an error, the PDF will remain `null`, and the loading modal will stay open.
      if (status === 201) {
        if (data.status !== 'completed') {
          setTimeout(() => {
            fetchAndDecodeBase64PDF()
          }, 5000);
          setPdf(null)
        } else {
          //If successful, the `pdf` state will be updated with the URL of the obtained PDF.
          toast.success('Poliza obtenida con exito!');
          setPdf(data.url)
          setIsSubmiting(false)
        }
      } else {
        setPdf(null);
        setIsSubmiting(false);
        toast.error('No hemos podido obtener tu poliza, intentalo mas tarde');
        router.push('/');
        return;
      }
    } catch (error) {
      toast.error('No hemos podido obtener tu poliza, intentalo mas tarde');
      router.push('/');
      setIsSubmiting(false);
      return;
    }
  }

  //Function to share the PDF URL via WhatsApp.
  function handleShare() {
    if (pdf) {
      const message = `¡Hola! Descarga este pdf en menos de 24 horas para conservarlo en tu dispositivo: ${pdf}`;
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
      window.open(whatsappUrl, "_blank");
    }
  }

  return (
    <>
      <section className=' w-[310px] lg:w-96 xl:w-97 h-72 xl:h-72 bg-white flex flex-col justify-between shadow-lg rounded-lg'>
        <article className='w-full h-16 rounded-t-lg bg-primary flex items-center text-xl 2xl:text-2xl font-semibold text-white px-4'>
          {company}
        </article>
        <article className='flex flex-col p-4 gap-y-2'>
          <p><strong className='mr-1'>Emisión:</strong>{emi}</p>
          <p><strong className='mr-1'>Vencimiento:</strong>{venc}</p>
          <p><strong className='mr-1'>Modelo:</strong>{modelo}</p>
        </article>
        <article className='h-20 border-t flex justify-end gap-x-4 items-center px-4'>
          {
            pdf ?
              <>
                <Link target='blank' href={pdf} className='ring-2 ring-primary bg-primary rounded-lg shadow px-2 py-2 text-sm flex items-center font-semibold gap-x-2'>
                  <h4 className='text-white'>Ver Pdf</h4>
                </Link>
                <button type='button' onClick={handleShare} className='ring-2 ring-primary bg-primary rounded-lg shadow px-2 py-2 text-sm flex items-center font-semibold gap-x-2'>
                  <h4 className='text-white'>Compartir Pdf</h4>
                </button>
              </>
              :
              <button type='button' onClick={fetchAndDecodeBase64PDF} className='ring-2 ring-primary bg-primary rounded-lg shadow px-2 py-2 text-sm flex items-center font-semibold gap-x-2'>
                <h4 className='text-white'>Obtener Pdf</h4>
              </button>
          }
        </article>
      </section>
      <LoadingModal open={isSubmiting} />
    </>
  )
}

export default Policie_Card