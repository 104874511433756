'use client'
import React, { useState } from 'react'
import useForm from '@/hooks/useForm';
import { CommonInput, CommonModal } from '@/components';
import { toast } from 'sonner';
import { authServices } from '@/services/Auth';

// This component is responsible for displaying a modal to manage password recovery.

// The user enters their email, and through an email service, they will receive a link. Once the link is clicked, it proceeds with the reset-password component located at `@/app/reset-password`.
function ForgotPassword({ authVisible, setAuthVisible }) {


  const { form, handleChange } = useForm({
    email: '',
  })

  const [isSubmiting, setIsSubmiting] = useState(false)

  /**
   * @function sendData - Sends the data to the server, and handles the response.
  */
  const sendData = async (e) => {
    e.preventDefault()
    setIsSubmiting(true)
    const { forgotPassword } = authServices()
    const { status, data } = await forgotPassword(form)
    switch (status) {
      case 201:
        toast.success(`Mail envidado`)
        break;
      case 401: toast.error('Usuario no encontrado')
        break;
      case 500:
        toast.error('Error en el recupero de Contraseña')
        break;
      default: toast.error('Error en el recupero de Contraseña')
    }
    setIsSubmiting(false)
  }

  const values = {
    name: 'email',
    title: 'Mail',
    titleColor: 'text-black',
    placeHolder: 'example@email.com...',
    type: 'email',
    minlength: '1',
    maxlength: '40',
    required: true
  };

  return (
    <CommonModal open={authVisible} setOpen={setAuthVisible}>
      <section className="relative z-10 flex flex-col gap-y-8 w-full h-auto" data-test-id={'forgotPassword-modal'}>
        <h1 className='text-2xl font-semibold'>Recuperar contraseña</h1>
        <p className='text-sm text-gray-600'>Te enviaremos un link al mail que escribas a continuación, luego podrás actualizar tu contraseña</p>
        <form onSubmit={sendData} className='flex flex-col w-full gap-y-5'>
          <article className={`w-full h-20`}>
            <CommonInput values={values} onChange={handleChange} />
          </article>
          <button disabled={isSubmiting} className={`w-full h-min py-3 text-white font-semibold text-sm rounded-full flex justify-center items-center bg-primary ${isSubmiting ? 'bg-opacity-30 animate-pulse ' : ''}`}>
            {isSubmiting ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </section>
    </CommonModal>
  )
}
export default ForgotPassword