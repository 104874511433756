import React from 'react'

const Policie_LoadingCard = () => {
  return (
    <section className='w-[310px] lg:w-96 xl:w-97 h-72 xl:h-72 bg-white flex flex-col justify-between shadow-lg rounded-lg'>
      <article className='w-full h-16 rounded-t-lg bg-gray-300 animate-pulse'>
      </article>
      <article className='flex flex-col p-4 gap-y-2'>
        <span className='w-40 h-5 rounded-lg bg-gray-300'></span>
        <span className='w-48 h-5 rounded-lg bg-gray-300'></span>
        <span className='w-32 h-5 rounded-lg bg-gray-300'></span>
      </article>
      <article className='h-20 border-t flex justify-end gap-x-4 items-center px-4'>
        <button className='rounded-md bg-gray-300 h-10 w-32 animate-pulse'>
        </button>
        <button className='rounded-md bg-gray-300 h-10 w-32 animate-pulse'>
        </button>
      </article>
    </section>
  )
}

export default Policie_LoadingCard