'use client'
import Image from 'next/image'
import React from 'react'
import { CommonButton } from '@/components'

//This section contains an "About Us" section.
const About = () => {
  
  return (
    <main className=' w-11/12 2xl:w-5/6 h-screen md:h-[80vh] flex flex-col md:flex-row justify-around items-center relative bg-white rounded-lg gap-y-4 md:gap-0' id='about'>
      <section className='w-full md:w-2/5 h-2/3 flex flex-col gap-y-2'>
        <h2 className='font-medium text-gray-500 text-xxs md:text-sm ml-1'>SOBRE NOSOTROS</h2>
        <Image
          src={'/marineBroker/logocompletoSinFondo.png'}
          alt='Quienes somos'
          width={400}
          height={100}
          quality={100}

        />
        <div className=' font-bold text-base lg:text-xl 2xl:text-2xl flex justify-start items-end text-gray-500' >Administramos tus seguros</div>
        <p className={`text-xxs lg:text-xs 2xl:text-sm text-start text-gray-500 font-medium`} >En Marine Broker, somos especialistas en ofrecer soluciones integrales de seguros para el sector marítimo y grandes empresas. Con años de experiencia en el mercado, nos enfocamos en proteger los activos más valiosos de nuestros clientes, brindando coberturas personalizadas que se adaptan a las complejidades y riesgos únicos de cada operación.
          Nuestro compromiso es claro: garantizar la tranquilidad y seguridad de su negocio, con un equipo de profesionales altamente capacitados en seguros marítimos, transporte y riesgos operacionales. Trabajamos de la mano con las principales aseguradoras del país y del mundo, ofreciendo productos innovadores y competitivos que aseguran la continuidad de su actividad frente a cualquier eventualidad.
          {/* 
            En un entorno de constante evolución, nuestra misión es proporcionar una gestión eficiente del riesgo, con soluciones diseñadas a la medida de sus necesidades, siempre con la seriedad y profesionalismo que nos caracteriza. Confiamos en que nuestra experiencia será su mejor aliado en la protección de su negocio. */}
        </p>
        <span>
          <CommonButton type='button' text='Contactanos' className=' text-white bg-primary hover:bg-opacity-90 px-6 md:px-8' />
        </span>
      </section>
      <section className='w-full md:w-2/5 h-2/3 relative'>
        <Image
          src={'/assets/QuienesSomos.jpg'}
          alt='Quienes somos'
          fill
          className='object-cover rounded-lg '
          quality={100}
        />
      </section>
    </main>
  )
}

export default About