'use client'
import Swal from 'sweetalert2';
import { signOutFunction } from '@/auth';


// This component displays a modal imported from Swal and shows the user's data.
// It renders three buttons: one to close the modal, another to navigate to user editing, and another to log out.
const UserData = (userData, checkCookies) => {

  let component = '';

  [
    { title: 'Correo electrónico', dbName: 'email' },
    { title: 'DNI', dbName: 'dni' },
  ].map(({ title, dbName }) => {
    component += userData[dbName] ? ` 
      <article key="${title}" class='w-full flex flex-col items-center h-20'>
        <h5 class='font-semibold text-sm'>${title}</h5>
        <h4 class='font-semibold text-xl'>${userData[dbName]}</h4>
      </article>
    ` : '';
  });

  Swal.fire({
    showConfirmButton: false,
    html: `<main data-test-id='delete-user-modal' class='h-auto w-full max-h-[700px] flex flex-col items-center justify-around'>
      <h3 class='font-bold text-2xl xl:text-3xl h-20 flex justify-center items-center'>Información del usuario</h3>
      <section class='w-full h-auto flex flex-col justify-around'>
        <article class='w-full h-20 flex flex-col items-center'>
          <h5 class='font-semibold text-sm'>Nombre completo</h5>
          <h4 class='font-semibold text-xl'>${userData.name}</h4>
        </article>
        ${component}
      </section>
    </main>
  `,
    focusConfirm: false,
    showCancelButton: true,
    cancelButtonText: 'Salir',
    cancelButtonColor: '#808080',
    showConfirmButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: 'Cerrar sesión',
    // showDenyButton: true,
    // denyButtonText: 'Editar datos',
    // denyButtonColor: '#481AA1',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      signOutFunction(checkCookies)
    }
  })

}

export default UserData