import { CommonModal } from '@/components';
import React from 'react'

const LoadingModal = ({ open }) => {

  return (
    <CommonModal open={open} setOpen={() => { }}>
      <main className='bg-white flex flex-col justify-around items-center h-96 px-4 py-8'>
        <h2 className='text-2xl font-semibold'>Espera un momento...</h2>
        <h4 className='text-base font-medium text-center'>Estamos obteniendo el PDF de tu póliza</h4>
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
        <h6 className='text-sm font-normal'>Tiempo estimado: <span className='text-primary font-semibold'>20</span> segundos</h6>
      </main>
    </CommonModal>
  )
}

export default LoadingModal;