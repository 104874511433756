import Cookies from "js-cookie";

// Base URL for API requests, loaded from environment variables
const URL = process.env.NEXT_PUBLIC_CURRENT_URL;

// Service function for authentication operations
export const authServices = () => {

  // Common headers configuration for POST requests
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
  }

  /**
   * @function login - Function to handle user login
   * @param {obj} form - {
   * email: string,
   * password: string
   * }
  */
  const login = async (form) => {

    try {
      console.log(URL);
      // Send a POST request to the login endpoint with the form data
      const response = await fetch(`${URL}/auth/login`, { ...headers, body: JSON.stringify(form) });
      const data = await response.json();

      // Check if the login was successful (HTTP status 201)
      if (response.status === 201) {
        // Store the session token in cookies with specific options
        Cookies.set('session', data.token, {
          path: '/',
          expires: 7,        // Cookie expires in 7 days
          secure: true,      // Only transmit cookie over HTTPS
          sameSite: 'Strict' // Prevents cookie from being sent in cross-site requests
        });

        return {
          data,
          status: response.status
        }
      }
      // If login fails, return null data and the response status
      return {
        data: null,
        status: response.status
      }
    } catch (error) {
      // Handle any errors during the request
      return {
        data: null,
        status: 500 // Return a server error status if an exception occurs
      }
    }
  }

  
  /**
   * @function register - Function to handle user registration
   * @param {obj} form - { 
   * name: string, 
   * email: string, 
   * password: string,
   * confirm_password: string,
   * dni: string, 
   * terms: bool 
   * }
  */
  const register = async (form) => {

    const {name, password, email, dni} =  form
    
    const body ={ name, password, email,  dni }

    console.log(body)

    try {
      // Send a POST request to the register endpoint with the form data
      const response = await fetch(`${URL}/auth/register`, { ...headers, body: JSON.stringify(body) });
      const data = await response.json();

      return {
        data,
        status: response.status
      }
    } catch (error) {
      // Log any errors to the console
      console.error('An error occurred:', error);
      return {
        data: null,
        status: 500 // Return a server error status if an exception occurs
      }
    }
  }

  /**
   * @function resetPassword - Function to handle password reset functionality
   * @param {string} newPassword - New password for the user
   * @param {string} token - Token sent and validate from the database
   */
  const resetPassword = async (newPassword, token) => {
    try {
      // Send a POST request to the reset password endpoint with the new password and token
      const response = await fetch(`${URL}/users/reset-password?token=${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        Accept: '*/*',
        body: JSON.stringify(newPassword) // Send the new password in the request body
      });
      const data = await response.json();

      return {
        data,
        status: response.status
      }
    } catch (error) {
      console.log(error);
      return {
        data: null,
        status: 500 // Return a server error status if an exception occurs
      }
    }
  }
  
  /**
   * @function forgotPassword - Function to handle forgot password functionality
   * @param {string} email - Email of the user who wants to recover their password
   */
  const forgotPassword = async (email) => {
    try {
      // Send a POST request to the forgot password endpoint with the user's email
      const response = await fetch(`${URL}/users/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        Accept: '*/*',
        body: JSON.stringify(email) // Send the email in the request body
      });

      const data = await response.json();

      return {
        data,
        status: response.status
      }
    } catch (error) {
      console.log(error);
      return {
        data: null,
        status: 500 // Return a server error status if an exception occurs
      }
    }
  }

  // Return the login and register functions for use in other parts of the app
  return {
    register,
    login,
    resetPassword,
    forgotPassword
  }
}
