'use client'
import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import Cookies from 'js-cookie'

const defaultdata = {
  title: 'título',
  url: './barco.jpeg',
  description: 'Descripcionasdada saddasdsadasdasddsa',
  alt: 'Barco'
}

//This component renders an insurance type card.
//If the card is clicked, the user is redirected to the following route, and the cookie is set with the value the user selected.
const InsuranceType_Card = ({ data = defaultdata, response }) => {

  const router = useRouter()

  /**
   * @function goToPolicies - Set the selected value in the cookies and redirect the user to the next URL
   */
  const goToPolicies = async () => {
    let dataToDb = { response, insuranceNumber: data.value }
    Cookies.set('insuranceType', JSON.stringify(dataToDb), { expires: 1 })
    router.push('/broker/insuranceType/policies')
  }

  return (
    <button type='button' onClick={goToPolicies} className='w-72 h-96 flex flex-col m-3 bg-white shadow-md shadow-slate-300 rounded-lg hover:shadow-lg hover:shadow-slate-400 hover:scale-105 transition-all duration-300'>
      <article className='h-1/2 w-full relative'>
        <Image
          src={data?.url}
          alt={`Typo` + data?.id}
          fill
          quality={100}
          className='rounded-t-lg'
        />
      </article>
      <article className='w-full h-1/2 flex flex-col items-start justify-start p-2 relative'>
        <h1 className='font-semibold text-lg'>{data.title}</h1>
        <div className='w-full h-14 border-y'> </div>
        <div className='w-10 h-10 flex justify-center items-center rounded-lg shadow bg-white absolute right-2 bottom-2'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-send-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z" /><path d="M6.5 12h14.5" /></svg>
        </div>
      </article>
    </button>
  )
}

export default InsuranceType_Card