'use client'
import Link from 'next/link'
import React from 'react'

const EmptyPolicies = ({ insuranceType }) => {

  return (
    <main className='h-[60vh] w-full flex flex-col justify-center items-center gap-y-8'>
      <h3 className='text-lg font-semibold'>
      No tienes pólizas con el tipo de seguro:
      </h3>
      <h4 className='ml-1 font-bold text-4xl text-primary'>{insuranceType}</h4>
      <Link href="/broker/insuranceType" className='px-5 py-3 text-white font-semibold bg-primary rounded-lg hover:scale-105 transition-all duration-300'>
        Volver atras
      </Link>
    </main>
  )
}

export default EmptyPolicies