import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\node_modules\\@mui\\material\\Box\\index.js");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\node_modules\\@mui\\material\\Modal\\index.js");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\node_modules\\next\\dist\\client\\image-component.js");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"]}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\node_modules\\sonner\\dist\\index.mjs");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\app\\components\\MobileDrawer.jsx");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\app\\components\\NavBar.jsx");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\app\\globals.css");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\components\\Cards\\InsuranceType_Card.jsx");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\components\\Cards\\Policie_Card.jsx");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\components\\common\\CommonInput.jsx");
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\Marine-Broker-Front\\src\\components\\feedBacks\\ErrorComponent.jsx")