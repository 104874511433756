import React from 'react'

const InsuranceType_LoadingCard = () => {

  const cssClasses = { texts: 'rounded-xl animate-pulse bg-gray-300', }

  return (
    <section className='w-72 h-96 flex justify-start flex-col m-3 rounded-lg shadow-lg'>
      <article className=' w-full h-2/4 flex justify-center items-center gap-y-4'>
        <svg className='w-16 h-16 text-gray-400 dark:text-gray-600 animate-pulse' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 20 18'>
          <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
        </svg>
      </article>
      <article className='flex flex-col justify-around items-start gap-y-5 p-4'>
        <span className={`w-4/5 h-7 ${cssClasses.texts}`}></span>
        <span className={`w-5/6 h-3 ${cssClasses.texts}`}></span>
        <span className={`w-3/4 h-3 ${cssClasses.texts}`}></span>
        <span className={`w-1/2 h-3 ${cssClasses.texts}`}></span>
      </article>
    </section>
  )
}

export default InsuranceType_LoadingCard