'use client'
import React from 'react'
import { Policie_Card } from '@/components';

const PolicieList = ({ policies, cod_sec, falseData }) => {

  return (
    <main className='w-full h-screen lg:h-[80vh] 2xl:h-[70vh] overflow-y-auto flex flex-col items-center lg:items-start lg:flex-row flex-wrap gap-10 py-4'>
      {
        policies.map(item => (
          <Policie_Card key={item.poliza} policie={item} cod_sec={cod_sec} />
        ))
      }
    </main>
  )
}

export default PolicieList