
const URL = process.env.NEXT_PUBLIC_CURRENT_URL;

// Service function for La Holando-specific API operations
export const laHolandoServices = () => {

  // Common headers configuration for POST requests
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    Accept: '*/*' // Accept any response type
  }

  // Function to handle login for La Holando API
  const laHolandoLogin = async () => {
    try {
      // Send a POST request to the login endpoint
      const response = await fetch(`${URL}/api/loginNovedadesEmisionLaHolando`, { ...headers });
      const data = await response.json();
      return {
        data,
        status: response.status
      }
    } catch (error) {
      return {
        data: null,
        status: 500 // Return a server error status if an exception occurs
      }
    }
  }

  /**
   * @function laHolandoObtenerRiesgos - Function to retrieve risk data from La Holando API
   * @param {string} sesion - Broker session obtained from the login endpoint in laHolandoLogin
   * @param {string} token - Broker token obtained from the login endpoint in laHolandoLogin
   * @param {string} type -Type of insurance requested by the user
   * @returns 
  */
  const laHolandoObtenerRiesgos = async (sesion, token, type) => {
    // Construct the request body with session, date, type, and authorization token
    const body = {
      "p_o_sesion": `${sesion}`,
      "p_fec_desde": "2023-11-15",    // Start date for risk data retrieval
      "p_fec_hasta": "2024-12-1",     // End date for risk data retrieval
      "p_cod_sec_desde": `${type}`,   // Start section code
      "p_cod_sec_hasta": `${type}`,   // End section code
      "xAuthorizationToken": token    // Authorization token for the request
    }

    // Uncommented example of hardcoded body data for testing purposes
    // const body = {
    //   "p_o_sesion": 47,
    //   "p_fec_desde": "2023-11-01",
    //   "p_fec_hasta": "2024-8-18",
    //   "p_cod_sec_desde": `3`,
    //   "p_cod_sec_hasta": `3`,
    //   "xAuthorizationToken": 5454545
    // }

    try {
      // Send a POST request to the risk processing endpoint with the body data
      const response = await fetch(`${URL}/api/procesarNovedadesEmisionLaHolando`, { ...headers, body: JSON.stringify(body) });
      const data = await response.json();
      return {
        data,
        status: response.status
      }

    } catch (error) {
      console.log(error);
      return {
        data: null,
        status: 500 // Return a server error status if an exception occurs
      }
    }
  }

  /**
 * @function laHolandoGetPoliza - Fetches a policy document (PDF) from the server based on the provided policy and section code.
 * @param {string} poliza - The policy number.
 * @param {string} codSec - The section code.
 */
const laHolandoGetPoliza = async (poliza, codSec) => {
  // Construct the request body with policy number, section code, and default endorsement.
  const body = {
    "p_poliza": poliza,
    "p_cod_sec": codSec,
    "p_endoso": 0
  };

  try {
    // Send a POST request to the server to fetch the policy document.
    const response = await fetch(`${URL}/api/obtenerPDF`, { 
      ...headers, 
      body: JSON.stringify(body) // Convert the body object to JSON format.
    });
    
    const data = await response.json(); // Parse the JSON response data.

    return {
      data, // The response data from the server.
      status: response.status // The HTTP status code of the response.
    };
  } catch (error) {
    console.error(error); // Log any errors that occur during the fetch process.

    // Return a fallback response indicating an internal server error.
    return {
      data: null, // No data is returned in case of an error.
      status: 500 // Default HTTP status for server errors.
    };
  }
};
  return {
    laHolandoLogin,
    laHolandoObtenerRiesgos,
    laHolandoGetPoliza
  }
}
