'use client'
import { deleteCookie } from '@/actions/coockiesActions';
import { toast } from 'sonner';
import Swal from 'sweetalert2';

//This function displays a Swal2 modal that logs out the user.
const signOutFunction = async (checkCookies) => {
  return Swal.fire({
    title: 'Estás seguro que deseas cerrar sesión?',
    icon: 'question',
    showConfirmButton: true,
    confirmButtonColor: '#481AA1',
    confirmButtonText: 'Confirmar',
    showCancelButton: true,
    cancelButtonColor: "#000",
    cancelButtonText: 'Cancelar',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      const response = deleteCookie('session')
      if (response) {
        toast.success('Has cerrado sesión exitosamente')
        checkCookies()
      } else {
        toast.error('Ocurrio un error inesperado, intentalo mas tarde')
      }
    }
  });
}

export default signOutFunction;