'use client'
import React, { useState } from 'react';
import ForgotPassword from './ForgotPassword';
import {Login} from '@/auth';

//This component is responsible for managing which modal will be displayed at each moment using a step system. For each step, it shows a different modal, and the step changes with the setStep state.
//This function is used to save states and make the code more readable.
const AuthSwitch = ({ checkCookies, setAuthVisible, authVisible }) => {

  //State to change the modal
  const [step, setStep] = useState(0)

  let component

  switch (step) {
    case 0: component = <Login checkCookies={checkCookies} authVisible={authVisible} setAuthVisible={setAuthVisible} setStep={setStep} />
      break;
    case 1: component = <ForgotPassword authVisible={authVisible} setAuthVisible={setAuthVisible} setStep={setStep} />
      break;
    default: component = <Login authVisible={authVisible} setAuthVisible={setAuthVisible} setStep={setStep} />
      break;
  }

  return (
    <div className='z-50'>
      {authVisible && component}
    </div>
  );

}

export default AuthSwitch;