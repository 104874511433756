//HOME 
export const attendanceCards = [
  {
    company: 'Sancor',
    companyImg: 'https://natalfwk.gruposancorseguros.com/nf-institucional/3.0.1/doc/imgs/logo.svg',
    data: [
      {
        logo: '/icons/phone.png',
        title: 'Denuncia de Siniestros Automotor:',
        subtitle: '0800 777 4643'
      },
      {
        logo: '/icons/phone.png',
        title: 'Emergencias médicas (integro): ',
        subtitle: '0800 555 2850'
      },
      {
        logo: '/icons/phone.png',
        title: 'Asistencia al vehículo y personas:',
        subtitle: '0800 333 2766'
      },
    ],
  },
  // {
  //   company: 'Cio',
  //   companyImg: '/logos/allianz.png',
  //   data: [
  //     {
  //       logo: '/icons/phone.png',
  //       title: 'Consultas y reclamos ART: ',
  //       subtitle: '0-800-999-4652'
  //     },
  //     {
  //       logo: '/icons/phone.png',
  //       title: 'Asistencia automotor: ',
  //       subtitle: '0-800-999-0002'
  //     },
  //     {
  //       logo: '/brands/whatsapp.png',
  //       title: 'Asistencia Embarcaciones: ',
  //       subtitle: '+54 911-3251-222'
  //     }
  //   ],
  // },
  {
    company: 'Allianz',
    companyImg: '/logos/laHolando-con-fondo.jpg',
    data: [
      {
        logo: '/icons/phone.png',
        title: 'Emergencias ART:',
        subtitle: '0-800-999-9719'
      },
      {
        logo: '/icons/phone.png',
        title: 'Asistencia automotor',
        subtitle: '0-800-999-0002'
      },
      {
        logo: '/icons/phone.png',
        title: 'Teléfono:',
        subtitle: '4321-7600'
      }
    ],
  },
  {
    company: 'Prevencion Art',
    companyImg: 'https://www.prevencionart.com.ar/resources/ART_color.svg',
    data: [
      {
        logo: '/icons/phone.png',
        title: 'Emergencias médicas:',
        subtitle: '0800 4444 278'
      },
      {
        logo: '/icons/phone.png',
        title: 'Consultas:',
        subtitle: '0800 5555 278'
      },
      {
        logo: '/brands/whatsapp.png',
        title: 'Consultas vía WhatsApp:',
        subtitle: '3493 66 1916'
      }
    ]
  }
]

export const skills = [
  {
    imgSrc: '/icons/insurance.png',
    title: 'Asegurados',
    description: 'Nuestros clientes cuentan con un servicio personalizado y especializado lo cual genera relaciones de largo plazo',
    alt: 'Chat image',
    id: 0
  },
  {
    imgSrc: '/icons/administrative.png',
    title: 'Administración de riesgos',
    description: 'Nos ocupamos de la cotización, comparación, contratación, actualización y renovación de tus pólizas',
    alt: 'Skills image',
    id: 1
  },
  {
    imgSrc: '/icons/innovation.png',
    title: 'Innovación',
    description: 'Contamos con un innovador sistema de gestión de pólizas con un rápido acceso en la misma página',
    alt: 'Graphic image',
    id: 2
  },
  {
    imgSrc: '/icons/academic.png',
    title: 'Capacitación y asesoramiento',
    description: 'Mediante unos simples pasos visualizarás todas tus pólizas y las podrás descargar y compartir ',
    alt: 'Tools image',
    id: 3
  }
]

export const contactInputs = [
  {
    name: 'user_name',
    title: 'Nombre completo',
    titleColor: 'text-white',
    placeHolder: 'Escribe tu nombre completo',
    type: 'text',
    minlength: '1',
    maxlength: '30',
    required: true,
    value: '',
    width: 'w-full',
    titleColor: 'text-white',
  },
  {
    name: 'user_email',
    title: 'Email',
    titleColor: 'text-white',
    placeHolder: 'example@email.com...',
    type: 'email',
    minlength: '8',
    maxlength: '40',
    required: true,
    value: '',
    width: 'w-full',
    titleColor: 'text-white',
  },
]

//GENERAL
export const desktop_navLinks = [
  {
    title: 'Útiles',
    scroll: '#attendance',
    offset: 200
  },
  {
    title: 'Contacto',
    scroll: '#contact',
    offset: 0
  },
  {
    title: 'Nosotros',
    scroll: '#about',
    offset: 100
  }
]

//GENERAL
export const mobile_navLinks = [
  {
    title: 'Útiles',
    scroll: '#attendance',
    offset: 0
  },
  {
    title: 'Contacto',
    scroll: '#contact',
    offset: 0
  },
  {
    title: 'Nosotros',
    scroll: '#about',
    offset: 0
  }
]

export const ModalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 370,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

//AUTH
export const loginInputs = [
  {
    name: 'email',
    title: 'Mail',
    titleColor: 'text-black',
    placeHolder: 'example@email.com...',
    type: 'email',
    minlength: '1',
    maxlength: '40',
    required: true,
    width: 'w-full'
  }
]

export const registerInptus = [
  {
    name: 'name',
    title: 'Nombre',
    placeHolder: 'Nombre...',
    type: 'text',
    minlength: '1',
    maxlength: '20',
    width: 'w-2/5'
  },
  {
    name: 'dni',
    title: 'DNI/CUIT',
    placeHolder: 'DNI/CUIT...',
    type: 'number',
    minlength: '8',
    maxlength: '11',
    width: 'w-2/5'
  },
  {
    name: 'email',
    title: 'Mail',
    placeHolder: 'example@email.com...',
    type: 'email',
    minlength: '1',
    maxlength: '40',
    width: 'w-full'
  },
  {
    name: 'password',
    title: 'Contraseña',
    placeHolder: '*********',
    type: 'password',
    minlength: '8',
    maxlength: '20',
    width: 'w-full'
  },
  {
    name: 'confirm_password',
    title: 'Confirmar contraseña',
    placeHolder: '*********',
    type: 'password',
    minlength: '8',
    maxlength: '20',
    width: 'w-full'

  }
]

//POLICIES
export const brokers = [
  {
    name: 'Nicolas Badalini',
    mail: 'nicobdada@gmail.com.',
    id: 0,
  }
]

export const _companys = [
  // {
  //   imgSrc: '/logos/allianz-con-fondo.png',
  //   imgAlt: '',
  //   value: 'Alianz',
  //   isSelected: false,
  //   id: 0
  // },
  // {
  //   imgSrc: '/logos/sancor-con-fondo.png',
  //   imgAlt: '',
  //   value: 'Sancor',
  //   isSelected: false,
  //   id: 1
  // },
  // {
  //   imgSrc: '/logos/rus-con-fondo.png',
  //   imgAlt: '',
  //   value: 'RUS',
  //   isSelected: false,
  //   id: 2
  // },
  {
    imgSrc: '/logos/laHolando-con-fondo.jpg',
    imgAlt: '',
    value: 'La Holando',
    isSelected: false,
    id: 3
  },
  // {
  //   imgSrc: '/logos/rivadavia-con-fondo.png',
  //   imgAlt: '',
  //   value: 'Rivadavia',
  //   isSelected : false,
  //   id: 4
  // },
  // {
  //   imgSrc: '/logos/allianz-con-fondo.png',
  //   imgAlt: '',
  //   value: 'Alianz',
  //   isSelected : false,
  //   id: 5
  // },
]

export const InsurancesType = [
  // 4, 5, 6, 9, 10, 11, 12, 13, 14, 15, 17, 21, 22 , 23, 24, 25, 26 , 27
  //la holando
  //   {
  //     "p_datos": [
  //         {
  //             "cod_sec": 1,
  //             "nombre": "Incendios"
  //         },
  //         {
  //             "cod_sec": 2,
  //             "nombre": "Combinados"
  //         },
  //         {
  //             "cod_sec": 3,
  //             "nombre": "Automotores"
  //         },
  //         {
  //             "cod_sec": 7,
  //             "nombre": "Granizo"
  //         },
  //         {
  //             "cod_sec": 8,
  //             "nombre": "Responsab. Civil"
  //         },
  //         {
  //             "cod_sec": 16,
  //             "nombre": "Seguro Tecnico"
  //         },
  //         {
  //             "cod_sec": 18,
  //             "nombre": "Transp. Cascos"
  //         },
  //         {
  //             "cod_sec": 19,
  //             "nombre": "Transp. Mercaderias"
  //         },
  //         {
  //             "cod_sec": 20,
  //             "nombre": "P. & I."
  //         },
  // }
  {
    title: 'Incendios',
    url: '/insuranceTypes/fire.jpg',
    value: 1,
    id: 0
  },
  {
    title: 'Combinados',
    url: '/insuranceTypes/car.jpg',
    value: 2,
    id: 1
  },
  {
    title: 'Automotores',
    url: '/insuranceTypes/car.jpg',
    value: 3,
    id: 2
  },
  {
    title: 'Granizo',
    url: '/insuranceTypes/granizo.jpg',
    value: 7,
    id: 3
  },
  {
    title: 'Responsab. Civil',
    url: '/insuranceTypes/respCivil.jpg',
    value: 8,
    id: 4
  },
  {
    title: 'Seguro Tecnico',
    url: '/insuranceTypes/tecnitial.jpg',
    value: 16,
    id: 5
  },
  {
    title: 'Transp. Cascos',
    url: '/insuranceTypes/car.jpg',
    value: 18,
    id: 6
  },
  {
    title: 'Transp. Mercaderias',
    url: '/insuranceTypes/transMerc.jpg',
    value: 19,
    id: 7
  },
  {
    title: 'P. & I.',
    url: '/insuranceTypes/pyl.png',
    value: 20,
    id: 8
  }
]